import React from 'react';
import Layout from '../components/layout/Layout';
import Button from '../components/Button';

type FormFieldProps = {
  name: string;
  className?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
};
const FormField: React.FC<FormFieldProps> = props => (
  <div className={props.className}>
    <label htmlFor={props.name} className="sr-only">
      {props.name}
    </label>
    <input
      id={props.name}
      name={props.name}
      type={props.type || 'text'}
      required={props.required === void 0 ? true : props.required}
      className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-400 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      placeholder={props.placeholder || props.name}
    />
  </div>
);

export default () => (
  <Layout>
    <div className="flex items-center justify-center lg:py-6">
      <div className="container w-full">
        <div>
          <h2 className="text-3xl text-center text-gray-900 lg:font-thin lg:text-5xl">
            Solicita una demostración
          </h2>
          <p className="mt-2 text-center text-gray-700">
            De nuestros productos o servicios para mas información también
            puedes llamarnos a 01 (55) 5525 . 8882
          </p>
        </div>
        <form
          className="max-w-4xl mx-auto mt-8"
          action="/contactmail.php"
          method="POST"
        >
          <div className="grid grid-cols-2 gap-3 mb-2">
            <FormField name="Name" />
            <FormField name="Email" type="email" />
          </div>
          <div className="hidden">
            <FormField name="testquestion" required={false} />
          </div>
          <FormField className="mb-2" name="Subject" />
          <div className="mb-6">
            <label htmlFor="message" className="sr-only">
              Message
            </label>
            <textarea
              rows={8}
              id="message"
              name="message"
              required
              className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-400 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Message"
            />
          </div>

          <div className="flex justify-end">
            <Button type="submit" className="w-full text-lg lg:w-auto">
              Solicita tu Demo
            </Button>
          </div>
        </form>
      </div>
    </div>
  </Layout>
);
